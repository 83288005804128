import { ActionType, createAction } from 'typesafe-actions'
import { ChangeTheme } from './types'

export const changeThemeAction = createAction('@theme/CHANGE_THEME')<ChangeTheme>()

const success = (state: ChangeTheme, action: ActionType<typeof changeThemeAction>): ChangeTheme => {
  document.documentElement.setAttribute('data-theme', action.payload.theme)
  return {
    ...state,
    theme: action.payload.theme
  }
}

export const changeThemeHandle = { asyncAction: changeThemeAction, success }
