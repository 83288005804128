import { Dispatch } from 'redux'
import { ChangeTheme, ThemeAction } from './types'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../store'
type useTheme = {
  themeState: ChangeTheme
  themeDispatch: Dispatch<ThemeAction>
}

export const useThemee = (): useTheme => {
  const themeState = useSelector<ApplicationState, ChangeTheme>(rootReducer => rootReducer.themeReducer)
  const themeDispatch = useDispatch<Dispatch<ThemeAction>>()
  return { themeState, themeDispatch }
}
