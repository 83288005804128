import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTIONS_URL
})

export const zApiMultiService = axios.create({
  baseURL: process.env.REACT_APP_Z_API
})

export const officialApiMultiService = axios.create({
  baseURL: process.env.REACT_APP_OFFICIAL_API
})

export const devzappApi = axios.create({
  baseURL: process.env.REACT_APP_DEVZAPP_API
})
