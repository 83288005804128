import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { DTO } from './types'

export const addMessageOnActiveChatAction = createAction('@chat/ADD_MESSAGE_ON_ACTIVE_CHAT_')<DTO>()

const success = (state: ChatState, action: ActionType<typeof addMessageOnActiveChatAction>): ChatState => {
  const messageToAdd = action.payload
  const updatedMessages = state.activeChat?.messages?.length ? [...state.activeChat?.messages] : []
  const messageIndex = updatedMessages?.findIndex(message => message.messageId === messageToAdd.messageId)
  if (messageIndex === -1) {
    updatedMessages.unshift(messageToAdd)
  } else {
    updatedMessages[messageIndex] = messageToAdd
  }

  return {
    ...state,
    activeChat: {
      ...state.activeChat,
      messages: updatedMessages
    }
  }
}

export const addMessageOnActiveChatHandle = { asyncAction: addMessageOnActiveChatAction, success }
