import React, { useEffect } from 'react'
import './assets/scss/themes.scss'
import { initFirebaseBackend } from './helpers/firebase'
import { useUser } from './redux/user'
import { saveAuthUser } from './redux/user/actions'
import { dark, light } from '@/styles/theme'
import { ThemeProvider } from 'styled-components'
import Routes from './routes'
import { useThemee } from './redux/theme'

initFirebaseBackend()

function App() {
  const { userDispatch } = useUser()
  const { themeState } = useThemee()

  const theme = themeState.theme === 'dark' ? dark : light

  useEffect(() => {
    const userAuthenticated = localStorage.getItem('devchat_app_userAuthenticated')
    if (userAuthenticated) {
      const parsedAuthUser = JSON.parse(userAuthenticated)
      userDispatch(
        saveAuthUser({
          email: parsedAuthUser.email,
          name: parsedAuthUser.name,
          companies: parsedAuthUser.companies,
          attendCampaigns: parsedAuthUser.attendCampaigns,
          id: parsedAuthUser.id,
          ownerId: parsedAuthUser.ownerId,
          isAdmin: parsedAuthUser.isAdmin,
          departments: parsedAuthUser.departments,
          isWork: parsedAuthUser.isWork,
          role: parsedAuthUser.role
        })
      )
    }
  }, [userDispatch])

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  )
}

export default App
