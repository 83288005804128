import { officialApiMultiService, zApiMultiService } from '@/services/api'
import { SendMessageDTO, SendMessageOfficialAPIDTO, SendMessageOfficialAPIResponse, SendMessageResponse } from './types'

export async function sendMessage({
  campaignId,
  token,
  body,
  documentType = 'send-text',
  extensionFile
}: SendMessageDTO) {
  const extension = documentType === 'send-document' ? extensionFile : ''
  const url = `/instances/campanhaId/${campaignId}/token/${token}/${documentType}/${extension}`

  const response = await zApiMultiService.post<SendMessageResponse>(url, body, {
    headers: {
      'Content-Type': 'application/json'
    }
  })

  return response.data
}

export async function sendMessageOfficialAPI({
  campaignId,
  wabaId,
  body,
  token
}: SendMessageOfficialAPIDTO): Promise<SendMessageOfficialAPIResponse> {
  let url = `/message/sendChat/${campaignId}/${wabaId}`
  if (body?.templateMessageId) {
    url += `/${body.templateMessageId}`
    delete body?.message
  }

  const response = await officialApiMultiService.post<SendMessageOfficialAPIResponse>(url, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })

  return response.data
}
