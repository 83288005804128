import { isObjectEmpty } from './object.helpers'

/**
 * Verifica se um elemento está vazio.
 * @param {*} element - O elemento a ser verificado.
 * @return {boolean} Retorna true se o elemento estiver vazio, caso contrário, retorna false.
 */
export function isEmpty(element) {
  return (
    element === '' ||
    element === undefined ||
    element === null ||
    element === 'undefined' ||
    isObjectEmpty(element) ||
    /^\s*$/.test(element)
  )
}

export function boldText(text: string) {
  if (typeof text !== 'string') return text
  return text.replace(/\*(.*?)\*/g, '<b>$1</b>')
}

/**
 * @param names - Array of names
 * @returns string - List of names separated by comma and 'e' for the last name
 * @example
 * getListOfNames(['John', 'Doe', 'Foo']) // 'John, Doe e Foo'
 * getListOfNames(['John', 'Doe']) // 'John e Doe'
 * getListOfNames(['John']) // 'John'
 */
export function getListOfNames(names: string[]) {
  if (!names?.length) {
    return ''
  }

  let lastName: string

  names.length > 1 && (lastName = names.pop())

  return `${names.join(', ')} ${lastName ? ` e ${lastName}` : ''}`
}
