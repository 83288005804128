import { DefaultTheme } from 'styled-components'

export const dark: DefaultTheme = {
  colors: {
    primary: '#F9FAFA',
    secondary: '#fff',
    alternativeSecodary: '#242424',
    tertiary: '#5D5D5D',
    surfaceBackground: '#8A84D8',
    accentColor: '#E7E7E7',
    tabs: '#1EA659',
    borderColorPrimary: '#464E53',
    borderColorSecondary: '#464e53',
    tooltipBackground: '#E3E6E9'
  },
  background: {
    primary: '#464E53',
    secondary: '#2F3437',
    tertiary: '#00Ae3f'
  },
  borderColor: {
    primary: '#4F4F4F'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  border: {
    radius: '4px'
  },
  font: {
    sizes: {
      title: {},
      body: {
        primary: '0.875rem'
      }
    },
    weight: {
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    }
  },
  spacings: {
    spacing_sm: '1rem',
    spacing_m: '1.5rem'
  },
  textMainPrimaryButton: '#2A2A2A',
  balonMessageIsReceived: '#3D3D3D',
  balonMessageIsSent: '#D3FFC8',
  textLabelInputDrawer: '#E7E7E7'
}

export const light: DefaultTheme = {
  colors: {
    primary: '#2A2A2A',
    secondary: '#fff',
    alternativeSecodary: '#242424',
    tertiary: '#888888',
    surfaceBackground: '#8A84D8',
    accentColor: '#3D3D3D',
    tabs: '#105B32',
    borderColorPrimary: '#E3E6E8',
    borderColorSecondary: '#E3E6E9',
    tooltipBackground: '#E3E6E9'
  },
  background: {
    primary: '#f7f7f7',
    secondary: '#F3F1EA',
    tertiary: '#00Ae3f'
  },
  borderColor: {
    primary: '#B0B0B0'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  border: {
    radius: '4px'
  },
  font: {
    sizes: {
      title: {},
      body: {
        primary: '0.875rem'
      }
    },
    weight: {
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    }
  },
  spacings: {
    spacing_sm: '1rem',
    spacing_m: '1.5rem'
  },
  textMainPrimaryButton: '#F6F6F6',
  balonMessageIsReceived: '#E7E7E7',
  balonMessageIsSent: '#DAFEE8',
  textLabelInputDrawer: '#3D3D3D'
}
