import { GlobalStyle } from '@/styles/global'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { dark, light } from '@/styles/theme'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import App from './App'
import { configureStore } from './redux/store'

const root = ReactDOM.createRoot(document.getElementById('root'))

const selectedTheme = 'light'
const theme = selectedTheme === 'light' ? light : dark
root.render(
  <Provider store={configureStore()}>
    <React.Fragment>
      <BrowserRouter>
        <GlobalStyle theme={theme} />

        <App />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
)
