import { createReducer } from 'typesafe-actions'
import { changeThemeHandle } from './handles/changeThemeAction'
import { ThemeAction, ChangeTheme } from './types'

const initialState: ChangeTheme = {
  theme: 'Light'
}

export const themeReducer = createReducer<ChangeTheme, ThemeAction>(initialState).handleAction(
  changeThemeHandle.asyncAction,
  changeThemeHandle.success
)
