import { ActionType, createAsyncAction } from 'typesafe-actions'
import { MessageState } from '../../types'
import { Failure, Request, Success } from './types'

export const fetchMessageTemplatesAction = createAsyncAction(
  '@message/FETCH_MESSAGE_TEMPLATES_REQUEST',
  '@message/FETCH_MESSAGE_TEMPLATES_SUCCESS',
  '@message/FETCH_MESSAGE_TEMPLATES_FAILURE'
)<Request, Success, Failure>()

const request = (state: MessageState): MessageState => ({
  ...state,
  isLoadingOnFetchMessageTemplates: true
})

const success = (state: MessageState, action: ActionType<typeof fetchMessageTemplatesAction.success>): MessageState => {
  return {
    ...state,
    messageTemplates: action.payload.messageTemplates,
    isSuccessOnFetchMessageTemplates: true,
    isLoadingOnFetchMessageTemplates: false
  }
}

const failure = (state: MessageState): MessageState => {
  return {
    ...state,
    isLoadingOnFetchMessageTemplates: false,
    isErrorOnFetchMessageTemplates: true
  }
}

export const fetchMessageTemplatesHandle = {
  asyncAction: fetchMessageTemplatesAction,
  request,
  success,
  failure
}
