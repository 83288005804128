import { combineReducers } from 'redux'
import { messageReducer } from './message/reducer'
import { userReducer } from './user/reducer'
import { chatReducer } from '@/redux/chat/reducer'
import { contactReducer } from '@/redux/contact/reducer'
import { companyReducer } from '@/redux/company/reducer'
import { departmentReducer } from './department/reducer'
import { stickerReducer } from './sticker/reducer'
import { themeReducer } from './theme/reducer'
export default combineReducers({
  messageReducer,
  chatReducer,
  userReducer,
  contactReducer,
  companyReducer,
  departmentReducer,
  stickerReducer,
  themeReducer
})
