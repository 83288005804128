import { _fireStore } from '@/helpers/firebase'
import { MessageModel } from '@/models/message.model'
import { sendMessageToZApiMultiService } from '@/redux/message/sagas/createMessageSaga'
import { SendMessageResponse } from '@/redux/message/sagas/createMessageSaga/http/message/types'
import { differenceInMilliseconds } from 'date-fns'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'
import { clearActiveChatAction, transferChatToOperatorAction } from '../../actions'
import { Chat } from '../../types'
import { CreateNewMessageDTO, HandleCustomMessageDTO } from './types'

export function* transferChatToOperatorSaga(action: ReturnType<typeof transferChatToOperatorAction.request>) {
  try {
    const { currentChat, currentOperator, newOperator, newDepartment, currentCampaign } = action.payload

    const sendedMessage = yield handleCustomMessage({
      campaign: currentCampaign,
      currentChat,
      newOperator
    })

    const dateNow = new Date().getTime()
    const updatedChatData: Chat = {
      ...currentChat,
      lastMessage: sendedMessage,
      department: newDepartment,
      departmentId: newDepartment.id,
      status: 'in_progress',
      operators: [
        { id: newOperator.id, name: newOperator.name, department: { id: newDepartment.id, name: newDepartment.name } }
      ],
      operatorsId: [newOperator.id],
      endDate: dateNow,
      duration: differenceInMilliseconds(new Date(dateNow), new Date(currentChat.startDate)),
      operatorsHistories: currentChat?.operatorsHistories?.length
        ? [
            ...currentChat?.operatorsHistories,
            { id: currentOperator.id, name: currentOperator.name, attend_at: dateNow }
          ]
        : [{ id: currentOperator.id, name: currentOperator.name, attend_at: dateNow }],
      updatedAt: dateNow
    }

    yield call(updateChat, currentChat.id, updatedChatData)
    yield put(transferChatToOperatorAction.success({ transferedChat: updatedChatData }))
    yield put(clearActiveChatAction())
  } catch (error) {
    yield put(transferChatToOperatorAction.failure())
  }
}

async function updateChat(chatId: string, updatedData: Partial<Chat>) {
  const chatDocRef = doc(collection(_fireStore, 'chats'), chatId)
  await updateDoc(chatDocRef, updatedData)
}

async function handleCustomMessage({ campaign, currentChat, newOperator }: HandleCustomMessageDTO) {
  const newMessage = await createNewMessage({
    campaign,
    currentChat,
    newOperator: { id: newOperator.id, name: newOperator.name },
    senderId: newOperator.id
  })
  const sendMessageResult: SendMessageResponse = await sendMessageToZApiMultiService(
    newMessage,
    campaign.id,
    campaign.token
  )
  newMessage.messageId = sendMessageResult.messageId

  await uploadMessageToFirestore(newMessage)
  return newMessage
}

async function createNewMessage({
  campaign,
  currentChat,
  newOperator,
  senderId
}: CreateNewMessageDTO): Promise<MessageModel> {
  return {
    campanhaId: campaign.id,
    ownerId: campaign.ownerId,
    file: null,
    messageText: `Atendimento sendo transferido para o operador *${newOperator.name}*, aguarde um momento.`,
    senderNumber: currentChat.requesterNumber,
    phone: currentChat.requesterNumber,
    senderId,
    connectedPhone: currentChat.receiverNumber,
    receiverNumber: currentChat.receiverNumber,
    chatId: currentChat.id,
    messageId: '',
    isRead: false,
    isSend: true,
    createdAt: new Date().getTime()
  } as MessageModel
}

async function uploadMessageToFirestore(message: MessageModel) {
  const collectionName = `messages`
  const newMessageDoc = doc(collection(_fireStore, collectionName), message.messageId)
  await setDoc(newMessageDoc, message)
}
